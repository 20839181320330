import { AppThemeConfig } from '../../shared/theme/app.theme'

export const appTheme: AppThemeConfig = {
  // ==============================
  // App
  // ==============================

  platformDetection: {
    container: {
      width: '100%',
      height: 'var(--view-height)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--header-background-color)',
      padding: 32,
    },
    wrapper: {
      borderRadius: 8,
      width: '100%',
      maxWidth: 500,
      height: '100%',
      maxHeight: 500,
      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '16px 32px',
      backgroundColor: 'white',
    },
    title: {
      marginTop: 16,
      fontSize: 18,
      textAlign: 'center',
    },
    description: {
      marginTop: 16,
      fontSize: 14,
      textAlign: 'center',
      marginBottom: 24,
    },
    ignoreLink: {
      marginTop: 32,
      color: 'var(--color-secondary)',
    },
    warningImage: {
      width: 96,
      marginBottom: 32,
    },
  },

  app: {
    wrapper: {
      background: 'rgba(0,0,0,0.03)',
      position: 'relative',
      width: '100vw',
      minHeight: 'var(--view-height)',
    },
    version: {
      display: 'none',
      position: 'absolute',
      bottom: 16,
      right: 16,
      fontSize: 10,
      color: '#9e9e9e',
      textAlign: 'right',
    },
  },

  // ==============================
  // Header
  // ==============================

  header: {
    outerWrapper: {
      background: 'var(--header-background-color)',
      boxShadow: 'var(--header-box-shadow)',
      height: 'var(--header-height)',
      minHeight: 'var(--header-height)',
      padding: '0 var(--form-padding-horizontal)',
      transition: 'background 1s ease-out 0.3s',
    },
    innerWrapper: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'space-between',
      margin: '0 auto',
      maxWidth: 'var(--layout-app-max-width)',
      overflow: 'hidden',
      padding: 0,
      width: '100%',
    },
    logoWrapper: {
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    logo: {
      width: 'var(--header-logo-width)',
    },
    menuWrapper: {
      alignSelf: 'flex-start',
      display: 'flex',
      alignItems: 'center',
      height: 'var(--header-height)',
    },
    menuIcon: {
      color: 'var(--header-title-color)',
      transition: 'opacity 0.2s ease-out',
      ':hover': {
        opacity: 0.5,
      },
    },
    pageTitle: {
      fontSize: 'var(--font-size-title)',
      marginRight: 'auto',
    },
    partnerName: {
      color: 'var(--header-title-color)',
      display: 'none',
      paddingLeft: 0,
      marginTop: 4,
    },
    supportNumberWrapper: {
      height: 'var(--header-height)',
      marginLeft: 'auto',
      paddingLeft: 24,
      paddingRight: 24,
      display: 'flex',
      alignItems: 'center',
    },
    supportNumberText: {
      fontSize: 'var(--font-size-p)',
      color: 'var(--header-title-color)',
    },
    supportIcon: {
      width: 20,
      height: 20,
      marginRight: 8,
    },
  },

  // ==============================
  // Footer
  // ==============================

  footer: {
    outerWrapper: {
      width: '100%',
      height: '80px',
      backgroundColor: 'var(--color-background-secondary)',
      '@media-mobile': {
        height: 'auto',
        paddingTop: 24,
        paddingBottom: 24,
      },
    },
    innerWrapper: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      margin: '0 auto',
      maxWidth: 'var(--layout-app-max-width)',
      paddingLeft: 24,
      paddingRight: 24,
      width: '100%',
      '@media-mobile': {
        flexDirection: 'column-reverse',
        gap: 24,
        alignItems: 'flex-start',
      },
    },
    footerContentWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 2,
      '@media-mobile': {
        flexDirection: 'column-reverse',
        gap: 24,
        alignItems: 'flex-start',
      },
    },
    legalWrapper: {
      fontSize: 12,
    },
    copyrightWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 32,
      flexGrow: 1,
    },
    copyrightCompanyName: {
      fontWeight: 400,
      fontSize: 12,
    },
    copyrightSubstring: {
      fontSize: 12,
    },
    languageIcon: {
      width: 20,
      height: 20,
      marginRight: 8,
    },
    linkText: {
      fontSize: 12,
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
    },
    languageLabel: {
      marginRight: 8,
    },
    languageWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    contactOuterWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',

      '@media-mobile': {
        marginLeft: 0,
      },
    },
    contactNumber: {
      marginLeft: 8,
      fontSize: 12,
    },
    contactLabel: {
      fontSize: 12,
      textDecoration: 'none',
    },
    contactIcon: {
      width: 20,
      height: 20,
      marginRight: 8,
    },
  },

  // ==============================
  // Loading
  // ==============================

  loading: {
    wrapper: {
      marginBottom: 32,
    },
    item: {
      backgroundColor: 'var(--color-active)',
      boxShadow: '2px 4px 8px rgb(0, 0, 0, 0.15)',
      width: 18,
      height: 18,
    },
    label: {
      color: 'var(--color-secondary)',
      fontSize: 20,
      textAlign: 'center',
      margin: '0 16px',
    },
  },

  apiLoader: {
    wrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: 'var(--view-height)',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      zIndex: 9999,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 48,
    },
  },

  // ==============================
  // Module delegator
  // ==============================

  moduleDelegator: {
    moduleContent: {
      paddingTop: 16,
      '@media-mobile': {
        paddingTop: 0,
      },
    },
    wrapper: {
      minHeight: 'var(--module-content-min-height)',
      maxWidth: 'var(--layout-app-max-width)',
      marginRight: 'auto',
      marginLeft: 'auto',
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderStyle: 'solid',
      borderColor: 'var(--color-background-secondary)',
      transition: 'min-height 0.5s ease-in-out',
    },
    content: {
      transition: 'min-height 0.5s ease-in-out',
      paddingBottom: 96,
      '@media-mobile': {
        background: 'var(--color-background-primary)',
        minHeight: 'var(--module-content-min-height)',
        paddingBottom: 64,
      },
    },
    contentWithoutFooter: {
      paddingBottom: 0,
      '@media-mobile': {
        paddingBottom: 0,
      },
    },
  },

  moduleBottomText: {
    wrapper: {
      padding: '16px var(--form-padding-horizontal) 0',
    },
    text: {
      fontSize: 'var(--font-size-p-small)',
      color: '--color-disabled',
      textAlign: 'center',
      '@media-mobile': {
        fontSize: 'var(--font-size-p)',
        textAlign: 'left',
      },
    },
  },

  // ==============================
  // Module header & footer
  // ==============================

  moduleHeader: {
    moduleHeaderAndNavWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
      overflow: 'hidden',
      position: 'relative',
      zIndex: 10,
    },
    outerWrapper: {
      width: '100%',
      marginBottom: 12,
      padding: '0 var(--form-padding-horizontal)',
      zIndex: 1,
      '@media-mobile': {
        background: 'var(--color-background-primary)',
        marginBottom: 0,
        marginTop: 0,
        paddingTop: 16,
      },
    },
    innerWrapper: {
      position: 'relative',
      padding: '32px 0 16px 0',
      maxWidth: 'var(--layout-app-max-width)',
      margin: '0 auto',
      '@media-mobile': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    contentWrapper: {},
    title: {
      color: 'var(--color-primary)',
      fontSize: 'var(--font-size-title)',
      fontWeight: 500,
      '@media-mobile': {
        display: 'none',
      },
    },
    description: {
      fontSize: 'var(--font-size-p)',
      marginTop: 24,
      '@media-mobile': {
        color: 'var(--color-secondary)',
        marginBottom: 24,
        marginTop: 16,
      },
    },
  },

  moduleFooter: {
    outerWrapper: {
      width: '100%',
    },
    innerWrapper: {
      maxWidth: 'var(--layout-content-max-width)',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '20px var(--form-padding-horizontal)',
      height: 80,
    },
  },
}
