import { createThemeConfigure, CSSProperties } from 'frr-web/lib/theme/configure.theme'
import { getUseCSSStyles, getUseInlineStyle } from 'frr-web/lib/theme/util'
import * as React from 'react'

export type AppTheme = {
  app: {
    wrapper: CSSProperties
    version: CSSProperties
  }

  apiLoader: {
    wrapper: CSSProperties
    item: CSSProperties
  }

  footer: {
    contactIcon: CSSProperties
    contactLabel: CSSProperties
    contactLabelWrapper: CSSProperties
    contactNumber: CSSProperties
    contactInnerWrapper: CSSProperties
    contactOuterWrapper: CSSProperties
    contactCardWrapper: CSSProperties
    copyrightCompanyName: CSSProperties
    copyrightSubstring: CSSProperties
    copyrightWrapper: CSSProperties
    footerContentWrapper: CSSProperties
    innerWrapper: CSSProperties
    languageIcon: CSSProperties
    languageLabel: CSSProperties
    languageOptionList: CSSProperties
    languageOption: CSSProperties
    languageOptionActive: CSSProperties
    languageWrapper: CSSProperties
    legalWrapper: CSSProperties
    link: CSSProperties
    linkText: CSSProperties
    outerWrapper: CSSProperties
  }
  header: {
    envList: CSSProperties
    envListItem: CSSProperties
    envListItemLabel: CSSProperties
    innerWrapper: CSSProperties
    link: CSSProperties
    linkText: CSSProperties
    logo: CSSProperties
    logoWrapper: CSSProperties
    menuWrapper: CSSProperties
    menuIcon: CSSProperties
    outerWrapper: CSSProperties
    pageTitle: CSSProperties
    pageTitleRight: CSSProperties
    pageTitleWrapper: CSSProperties
    partnerName: CSSProperties
    supportIcon: CSSProperties
    supportNumberText: CSSProperties
    supportNumberWrapper: CSSProperties
  }

  loading: {
    wrapper: CSSProperties
    item: CSSProperties
    label: CSSProperties
  }

  moduleBottomText: {
    wrapper: CSSProperties
    text: CSSProperties
  }
  moduleDelegator: {
    wrapper: CSSProperties
    content: CSSProperties
    contentWithoutFooter: CSSProperties
    moduleContent: CSSProperties
  }
  moduleFooter: {
    outerWrapper: CSSProperties
    innerWrapper: CSSProperties
  }
  moduleHeader: {
    moduleHeaderAndNavWrapper: CSSProperties
    outerWrapper: CSSProperties
    innerWrapper: CSSProperties
    contentWrapper: CSSProperties
    title: CSSProperties
    description: CSSProperties
  }

  platformDetection: {
    container: CSSProperties
    description: CSSProperties
    ignoreLink: CSSProperties
    title: CSSProperties
    warningImage: CSSProperties
    wrapper: CSSProperties
  }
}

const defaultAppTheme: AppTheme = {
  app: {
    wrapper: {},
    version: {},
  },
  apiLoader: {
    wrapper: {},
    item: {},
  },
  footer: {
    contactIcon: {},
    contactLabel: {},
    contactLabelWrapper: {},
    contactNumber: {},
    contactCardWrapper: {},
    contactInnerWrapper: {},
    contactOuterWrapper: {},
    copyrightCompanyName: {},
    copyrightSubstring: {},
    copyrightWrapper: {},
    footerContentWrapper: {},
    innerWrapper: {},
    languageIcon: {},
    languageLabel: {},
    languageOptionList: {},
    languageOption: {},
    languageOptionActive: {},
    languageWrapper: {},
    legalWrapper: {},
    link: {},
    linkText: {},
    outerWrapper: {},
  },

  header: {
    envList: {},
    envListItem: {},
    envListItemLabel: {},
    innerWrapper: {},
    link: {},
    linkText: {},
    logo: {},
    logoWrapper: {},
    menuWrapper: {},
    menuIcon: {},
    outerWrapper: {},
    pageTitle: {},
    pageTitleRight: {},
    pageTitleWrapper: {},
    partnerName: {},
    supportIcon: {},
    supportNumberText: {},
    supportNumberWrapper: {},
  },

  loading: {
    wrapper: {},
    item: {},
    label: {},
  },

  moduleBottomText: {
    wrapper: {},
    text: {},
  },
  moduleDelegator: {
    wrapper: {},
    content: {},
    contentWithoutFooter: {},
    moduleContent: {},
  },
  moduleFooter: {
    outerWrapper: {},
    innerWrapper: {},
  },
  moduleHeader: {
    moduleHeaderAndNavWrapper: {},
    outerWrapper: {},
    innerWrapper: {},
    contentWrapper: {},
    title: {},
    description: {},
  },

  platformDetection: {
    container: {},
    description: {},
    ignoreLink: {},
    title: {},
    warningImage: {},
    wrapper: {},
  },
}

export type AppThemeConfig = {
  [k in keyof AppTheme]?: Partial<AppTheme[k]>
}

export const useAppTheme = (): AppTheme => {
  const theme = React.useContext(AppThemeContext)
  if (!theme) {
    throw new Error(`AppTheme not found`)
  }
  return theme
}

export const AppThemeContext = React.createContext<AppTheme>(undefined as unknown as AppTheme)

AppThemeContext.displayName = 'App Theme Context'

export const configureAppTheme = createThemeConfigure<any, AppTheme>(defaultAppTheme)

export const useInlineStyle = getUseInlineStyle<AppTheme>()
export const useCSSStyles = getUseCSSStyles<AppTheme>()
