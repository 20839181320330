/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BobBaseApp_AppIdentifierEnum {
    UNKNOWN = 'unknown',
    BOB_APPLE = 'bob_apple',
    BOB_INVOICE = 'bob_invoice',
    BOB_CREDIT = 'bob_credit',
    BOB_ZERO = 'bob_zero',
}
