import { Navigation as NavigationComponent, NavigationItem } from 'frr-web/lib/app/Navigation'
import { StateLevels, findStateLevelInfo } from '../../../shared/helpers.stateLevel'
import { ProductSettingsT } from '../../../types/financing.types'
import { ModuleSystemConfig } from '../../../types/frontend.types'
import { useEngine } from '../../context/Engine.context'
import { useStateLevels } from '../../context/Data.context'
import { useProduct } from '../../context/PartnerProduct.context'

type NavigationProps = {
  group?: any
  groupToLabel: any
  isError: boolean
  title?: string
}

const checkIsModuleSkipped =
  (params: { product: ProductSettingsT; stateLevels: StateLevels }) =>
  (moduleSystem: ModuleSystemConfig<any, any>) => {
    // If no check exists do not skip module system
    if (!moduleSystem.isModuleSkipped) return false

    // Check for existing level states with withCheckStates is active
    if (moduleSystem.isModuleSystem.states && 'withStatesCheck' in moduleSystem.isModuleSkipped) {
      const exists = moduleSystem.isModuleSystem.states.reduce(
        (acc, stateLevel) => acc || findStateLevelInfo(params.stateLevels.stateList, stateLevel).exists,
        false,
      )
      return !exists
    }

    // Use withProduct function check
    if ('withProduct' in moduleSystem.isModuleSkipped) {
      return moduleSystem.isModuleSkipped.withProduct({ product: params.product })
    }

    // Do not skip per default
    return false
  }

export const Navigation = (props: NavigationProps) => {
  const { product, configuration } = useProduct()
  const { stateLevels } = useStateLevels()
  const engine = useEngine()

  let items: Array<NavigationItem> = []

  const isModuleSkipped = checkIsModuleSkipped({ product, stateLevels })

  //if (props.group !== 'Error') {
  items = engine.moduleSystems
    .reduce(
      (acc, system, index) =>
        acc.find(
          (a) =>
            a.group === system.group && a.isNotPartOfNavigation === !!system.isModuleNotPartOfNavigation,
        )
          ? acc
          : [
              ...acc,
              {
                group: system.group,
                isNotPartOfNavigation: !!system.isModuleNotPartOfNavigation,
                isSkipped: isModuleSkipped(system),
                index,
              },
            ],
      items,
    )
    .filter((item) => !['NoNavigation', 'Error'].includes(item.group) && !item.isSkipped)
  // }

  return (
    <NavigationComponent
      items={items}
      isError={props.isError}
      navigationItems={items
        .filter((item) => !item.isNotPartOfNavigation)
        .map((item, itemIndex) => ({
          label: props.groupToLabel[item.group],
          value: item.group,
          stepNumber: configuration.navigationConfig.showStepNumberAsCircle
            ? `${itemIndex + 1}`
            : `${itemIndex + 1}. `,
        }))}
      navigationActiveType={configuration.navigationConfig.activeType}
      selectedItem={props.group}
      showStepNumberAsCircle={configuration.navigationConfig.showStepNumberAsCircle}
      title={props.title}
    />
  )
}
