import { Div, Img, P } from 'frr-web/lib/html'
import { createStyled } from 'frr-web/lib/theme/util'
import { SyntheticEvent, useEffect, useState } from 'react'
import { AppTheme, useAppTheme, useCSSStyles } from '../../../shared/theme/app.theme'
import styled from 'styled-components'

const LogoSVG = (props: { src: string; style?: Partial<AppTheme['header']> }) => {
  const [svg, setSvg] = useState({ isLoading: true, data: null })

  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'header')(props.style)

  useEffect(() => {
    setSvg({ isLoading: true, data: null })
    fetch(props.src)
      .then((res) => res.text())
      .then((data: any) => setSvg({ isLoading: false, data }))
  }, [props.src])

  return svg.isLoading ? null : (
    <DivSig {...getCSSStyles('logo')} dangerouslySetInnerHTML={{ __html: `${svg.data}` }} />
  )
}

export const Logo = (props: {
  alt?: string | null
  link?: string | null
  onLoad?: (img: SyntheticEvent<HTMLImageElement>) => void
  partnerName: string | null
  src: string
  style?: Partial<AppTheme['header']>
}) => {
  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'header')(props.style)

  const isSVG = props.src && props.src.endsWith('.svg')

  return (
    <Div {...getCSSStyles('logoWrapper')}>
      {props.link ? (
        <a href={props.link!} target='_self'>
          {isSVG ? (
            <LogoSVG src={props.src} style={props.style} />
          ) : (
            <Img {...getCSSStyles('logo')} alt={props.alt} src={props.src} onLoad={props.onLoad} />
          )}
        </a>
      ) : isSVG ? (
        <LogoSVG src={props.src} style={props.style} />
      ) : (
        <Img {...getCSSStyles('logo')} alt={props.alt} src={props.src} onLoad={props.onLoad} />
      )}

      {props.partnerName !== null && props.partnerName! > '' && (
        <P
          {...getCSSStyles('partnerName')}
          label={props.partnerName || 'Default name'}
          isLabelTranslated
        ></P>
      )}
    </Div>
  )
}

const DivSig = createStyled(styled.div`
  > svg {
    height: auto;
    width: 100%;
  }
`)
