import { P } from 'frr-web/lib/html'
import { ReactNode } from 'react'
import { useAppTheme, useCSSStyles } from '../../../shared/theme/app.theme'

export const FooterLink = ({
  linkUrl = '#',
  linkText,
  target = '_blank',
  children,
}: {
  linkUrl?: string | null
  linkText?: string | null
  target?: '_blank' | '_self'
  children?: ReactNode
}) => {
  const theme = useAppTheme()
  const getStyle = useCSSStyles(theme, 'footer')()

  return (
    <a className='link__footer' href={linkUrl ?? undefined} target={target}>
      {linkText && <P label={linkText} {...getStyle('linkText')} />}
      {children}
    </a>
  )
}
