import { Div, H1, P } from 'frr-web/lib/html'
import { useEffect, useState } from 'react'
import { useAppTheme, useCSSStyles } from '../../../shared/theme/app.theme'
import { OnboardingChannel } from '../../../types/financing.types'
import { ModuleState, NavigationPlacement } from '../../../types/frontend.types'
import { useOptionalFinObj } from '../../context/Data.context'
import { useEngine } from '../../context/Engine.context'
import { useOptionalLocalFinObjData } from '../../context/LocalData.context'
import { useModuleSystem } from '../../context/ModuleSystem.context'
import { useWhitelabelNavigation } from '../../context/Navigation.context'
import { useProduct } from '../../context/PartnerProduct.context'
import { getCSSVariable, setCSSVariable } from '../../providers/Theme.provider'
import { Navigation } from '../navigation/Navigation'

export const ModuleHeader = () => {
  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'moduleHeader')({})

  const { groupToLabel } = useEngine()
  const finObj = useOptionalFinObj()
  const localFinObj = useOptionalLocalFinObjData()
  const { product, configuration } = useProduct()
  const { moduleConfig, moduleSystem, moduleState } = useModuleSystem()
  const { isHeaderDarkMode, isNavigationHidden } = useWhitelabelNavigation()

  // Get module header and footer
  const moduleHeaderProps = moduleConfig ? moduleConfig.moduleHeader : undefined

  const shouldHideNavigation =
    !moduleSystem ||
    !moduleState ||
    isNavigationHidden ||
    (moduleSystem.group === 'NoNavigation' && !moduleHeaderProps) ||
    !!moduleHeaderProps?.isNavigationHidden

  const [backgroundColor, setBackgroundColor] = useState<string>('')
  useEffect(() => {
    // Save original header color
    setBackgroundColor(getCSSVariable('--header-background-color'))
  }, [])

  useEffect(() => {
    const minHeight = shouldHideNavigation
      ? 'calc(var(--view-height) - var(--header-height))'
      : 'calc(var(--view-height) - var(--header-height) - var(--header-navigation-height) - 16px)'
    const headerBackgroundColor = isHeaderDarkMode
      ? 'var(--header-background-color-dark)'
      : backgroundColor

    const timerId = setTimeout(() => {
      setCSSVariable('--module-content-min-height', minHeight)
      setCSSVariable('--header-background-color', headerBackgroundColor)
    }, 500)

    return () => clearTimeout(timerId)
  }, [shouldHideNavigation, isHeaderDarkMode])

  if (!moduleConfig) {
    return <></>
  }

  return (
    <Div
      {...getCSSStyles('moduleHeaderAndNavWrapper', {
        maxHeight: shouldHideNavigation ? 0 : 320,
        transition: 'max-height 0.7s ease-in-out',
      })}
    >
      {moduleConfig.moduleHeader && (
        <Div {...getCSSStyles('outerWrapper')}>
          <Div {...getCSSStyles('innerWrapper')}>
            <H1 {...getCSSStyles('title')} label={moduleConfig.moduleHeader.title} />
            <Div {...getCSSStyles('contentWrapper')}>
              {moduleConfig.moduleHeader?.description &&
                (product.onboardingChannel === OnboardingChannel.BPL_RETAIL ||
                  product.onboardingChannel === OnboardingChannel.BPL_ECOMMERCE ||
                  configuration.navigationConfig.placement === NavigationPlacement.AboveTitle) && (
                  <P
                    {...getCSSStyles('description')}
                    label={moduleConfig.moduleHeader.description}
                    data={
                      finObj
                        ? {
                            partnerName: product.displayName || '',
                            salutation: finObj.customerInfo.baseInfo.gender ? 'Mr' : 'Mrs',
                            surname: finObj.customerInfo.baseInfo.surname || '',
                            email: finObj.customerInfo.baseInfo.email || '',
                          }
                        : {
                            partnerName: product.displayName || '',
                            salutation: '',
                            surname: '',
                            email: '',
                          }
                    }
                  />
                )}
            </Div>
          </Div>
        </Div>
      )}

      {moduleSystem.group !== 'NoNavigation' && (
        <Navigation
          group={moduleSystem.group}
          groupToLabel={groupToLabel}
          isError={
            moduleState === ModuleState.Error ||
            (localFinObj !== undefined &&
              !!moduleSystem.isModuleNavigationError?.(localFinObj.financing))
          }
          title={moduleHeaderProps?.title}
        />
      )}
    </Div>
  )
}
