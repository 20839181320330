import { createContext, ReactNode, useContext, useEffect } from 'react'
import { useSetModuleSystem } from '../../data/mutations/financingObject.mutations'
import { checkIsActiveStateInLevelState, StateLevels } from '../../shared/helpers.stateLevel'
import { FinancingObject, PreFinancingObject } from '../../types/financing.types'
import {
  EngineConfig,
  Module,
  ModuleConfig,
  ModuleState,
  ModuleSystemConfig,
  PartnerFrontendConfiguration,
} from '../../types/frontend.types'
import { useOptionalFinObj, useStateLevels } from './Data.context'
import { useEngine } from './Engine.context'
import { useLocalPreFinObjData, useOptionalLocalFinObjData } from './LocalData.context'
import { useModuleLoaderContext } from './ModuleLoader.context'
import { useWhitelabelNavigation } from './Navigation.context'
import { getDefaultModuleSystem } from '../../shared/helpers.withEnvVars'
import { useAppConfig } from '../providers/AppConfigData.provider'
import { useProduct } from './PartnerProduct.context'
import { AppConfig } from '../../types/app.types'
import { FinancingObjectFormDataT } from '../../types/form.types'

const defaultModuleSystem = getDefaultModuleSystem()

const getInitialModuleSystem = (params: {
  appConfig: AppConfig
  configuration: PartnerFrontendConfiguration
  financing: FinancingObjectFormDataT | undefined
  engine: EngineConfig
}): string | undefined => {
  if (params.engine.computeInitialModuleSystem && params.financing && !defaultModuleSystem) {
    return params.engine.computeInitialModuleSystem({
      appConfig: params.appConfig,
      configuration: params.configuration,
      financing: params.financing,
    })
  }
  return undefined
}

const getCurrentModuleSystem = (params: {
  engine: EngineConfig
  finObj: FinancingObject | undefined
  moduleSystemOverride: string | null
  preFinObj: PreFinancingObject
  stateLevels: StateLevels
}) => {
  let nextModuleSystem = params.engine.moduleSystems.find((s) => s.id === params.moduleSystemOverride)

  if (!nextModuleSystem) {
    nextModuleSystem = params.engine.moduleSystems.find((s) => {
      // With level states
      if (s.isModuleSystem.states) {
        return checkIsActiveStateInLevelState({
          states: s.isModuleSystem.states,
          stateLevels: params.stateLevels,
        })
      }

      // With financing object
      if (params.finObj) {
        // if (
        //   (s.isModuleSystem.orderStates || []).includes(
        //     params.finObj.stateLevelInfo.activeStateLevelBackend as OrderState,
        //   )
        // ) {
        //   return true
        // }

        if (s.isModuleSystem.withFinObj) {
          return s.isModuleSystem.withFinObj(params.finObj)
        }
      } else {
        // Without financing object
        return !!s.isModuleSystem.withoutFinObj?.()
      }

      return false
    })
  }

  if (!nextModuleSystem) {
    nextModuleSystem = params.engine.errorSystem
  }

  const nextModuleState = nextModuleSystem.computeModuleState({
    finObj: params.finObj,
    moduleSystem: nextModuleSystem.id,
  })

  let nextModule = nextModuleSystem.modules[nextModuleState]

  if (!nextModule && nextModuleState === ModuleState.Loading) {
    nextModule = { type: Module.Generic, title: 'loading' }
  }

  return {
    moduleConfig: nextModule,
    moduleSystem: nextModuleSystem,
    moduleState: nextModuleState,
  }
}

export type ModuleSystemContextT = {
  moduleConfig: ModuleConfig | undefined
  moduleSystem: ModuleSystemConfig<{}, {}>
  moduleState: ModuleState
}

const ModuleSystemContext = createContext<ModuleSystemContextT | undefined>(undefined)
ModuleSystemContext.displayName = 'ModuleSystemContext'

export const useModuleSystem = (): ModuleSystemContextT => {
  const moduleSystem = useContext(ModuleSystemContext)
  if (!moduleSystem) {
    throw new Error(`Module system not found.`)
  }
  return moduleSystem
}

export type ModuleSystemOptionalContextT = {
  moduleConfig?: ModuleConfig | undefined
  moduleSystem?: ModuleSystemConfig<{}, {}>
  moduleState?: ModuleState
}

export const useModuleSystemOptional = (): ModuleSystemOptionalContextT => {
  const moduleSystem = useContext(ModuleSystemContext)
  return moduleSystem || {}
}

export const ModuleSystemProvider = (props: { children: ReactNode }) => {
  const { appConfig } = useAppConfig()
  const { product, configuration } = useProduct()
  const engine = useEngine()
  const finObj = useOptionalFinObj()
  const localFinObj = useOptionalLocalFinObjData()
  const { value: preFinObj } = useLocalPreFinObjData()
  const { moduleSystemOverride } = useModuleLoaderContext()
  const { stateLevels } = useStateLevels()

  const setModuleSystem = useSetModuleSystem()

  // Compute current module system in the engine's flow
  const value = getCurrentModuleSystem({
    engine,
    finObj: localFinObj ? finObj : undefined,
    moduleSystemOverride,
    preFinObj,
    stateLevels,
  })

  // Set engine's initial module system
  useEffect(() => {
    const initial = getInitialModuleSystem({
      appConfig,
      configuration,
      engine,
      financing: finObj ? { ...finObj, product } : undefined,
    })
    setModuleSystem(initial)
  }, [engine])

  // Change language control in navigation
  const { setNavigationChangeLanguage } = useWhitelabelNavigation()

  useEffect(() => {
    setNavigationChangeLanguage({
      isChangeLanguageDisabled: !!value.moduleConfig?.isChangeLanguageDisabled,
      isPatchOnChangeLanguageActive: !!value.moduleConfig?.isPatchOnChangeLanguageActive,
    })
  }, [value.moduleConfig?.isChangeLanguageDisabled, value.moduleConfig?.isPatchOnChangeLanguageActive])

  return <ModuleSystemContext.Provider value={value}>{props.children}</ModuleSystemContext.Provider>
}
