import { Div, Img, P, Span } from 'frr-web/lib/html'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppTheme, useAppTheme, useCSSStyles } from '../../../shared/theme/app.theme'
import { useProduct } from '../../context/PartnerProduct.context'
import { FooterLink } from './FooterLink'
import { Link } from 'frr-web/lib/components/Link'
import { PFChevronRight } from '../../icons/PFChevronRight'
import styled from 'styled-components'
import { createStyled } from 'frr-web/lib/theme/util'

const FooterLanguageSelector = React.lazy(() => import('./FooterLanguageSelector'))

export type Props = {
  style?: Partial<AppTheme['footer']>
}

export const Footer = (props: Props) => {
  const { t: translate } = useTranslation()
  const theme = useAppTheme()
  const getCSSStyles = useCSSStyles(theme, 'footer')(props.style)
  const { product, configuration } = useProduct()

  if (configuration.footerConfig.hideFooter) {
    return null
  }

  return (
    <>
      {configuration.footerConfig.showSupportNumber && product.partnerCustomerSupportPhoneNumber && (
        <Div {...getCSSStyles('contactOuterWrapper')}>
          <Div {...getCSSStyles('contactInnerWrapper')}>
            <Div {...getCSSStyles('contactCardWrapper')} tabIndex={0}>
              <Link
                icon={{ type: 'contact', style: getCSSStyles('contactIcon') }}
                label={
                  <StyledContactLabel {...getCSSStyles('contactLabelWrapper')}>
                    {translate('footer.contactText', {
                      supportNumber: product.partnerCustomerSupportPhoneNumber,
                    })}
                    <PFChevronRight />
                  </StyledContactLabel>
                }
                style={getCSSStyles('contactLabel')}
                onClick={() =>
                  window.open(
                    `tel:${product.partnerCustomerSupportPhoneNumber!.replace(' ', '')}`,
                    '_self',
                  )
                }
              />
            </Div>
          </Div>
        </Div>
      )}

      <Div {...getCSSStyles('outerWrapper')}>
        <Div {...getCSSStyles('innerWrapper')}>
          {configuration.footerConfig.showCopyright && (
            <Div {...getCSSStyles('copyrightWrapper')}>
              <P label={'footer.copyrightText'} {...getCSSStyles('copyrightSubstring')} />
            </Div>
          )}

          <Div {...getCSSStyles('footerContentWrapper')}>
            <Div {...getCSSStyles('legalWrapper')}>
              <FooterLink
                linkText={'footer.legalLinkText'}
                linkUrl={translate('footer.legalLinkUrl')}
                target='_blank'
              />
            </Div>

            {configuration.footerConfig.showLanguageMenu && (
              <FooterLanguageSelector style={props.style} />
            )}

            {!configuration.footerConfig.showSupportNumber &&
              product.partnerCustomerSupportPhoneNumber !== null &&
              product.partnerCustomerSupportPhoneNumber > '' && (
                <Div {...getCSSStyles('contactOuterWrapper')}>
                  <Link
                    className='link__footer'
                    icon={{ type: 'contact', style: getCSSStyles('contactIcon') }}
                    label={product.partnerCustomerSupportPhoneNumber}
                    style={getCSSStyles('contactLabel')}
                    onClick={() =>
                      window.open(
                        `tel:${product.partnerCustomerSupportPhoneNumber!.replace(' ', '')}`,
                        '_self',
                      )
                    }
                  />
                </Div>
              )}
          </Div>
        </Div>
      </Div>
    </>
  )
}

const StyledContactLabel = createStyled(styled.span`
  padding-top: 2px;

  svg {
    width: 10px;
    height: 20px;
    margin-top: -2px;
  }
`)
